import React from 'react';
import { WidgetViewModel } from '../../../../viewModel/viewModel';
import { PremiumDialog } from './PremiumDialog/PremiumDialog';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { WidgetActions } from '../../../../actions/actions';
import { DialogType } from '../../../../viewModel/dialogViewModel/dialogViewModel';

export type DialogProps = {
  widgetActions: WidgetActions;
  widgetViewModel: WidgetViewModel;
};

export const Dialog: React.FC<DialogProps> = (props) => {
  const { t } = useTranslation();
  const { widgetActions, widgetViewModel } = props;
  const { dialogViewModel } = widgetViewModel;
  if (DialogType.Premium === dialogViewModel.type) {
    return (
      <PremiumDialog
        isOpen={dialogViewModel.isOpen}
        content={t('user-message.not-bookable')}
        confirmationButtonLabel={t('user-message.action-ok-label')}
        onClose={() => widgetActions.onDialogClose()}
      />
    );
  }
  return null;
};
